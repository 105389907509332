import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//global.CONFIG = require('./page.config.js')

// import BOOTSTRAP
//import 'ASSETS/scss/bootstrap/bootstrap.scss'
// import CUSTOM
import 'ASSETS/scss/custom.scss'

// imports LIBS


import { Navbar, Footer, Modal1, Modal2} from './components/index.js'

import Tdefault from './templates/Tdefault'


// Create App
const app = createApp(App)
app.use(store)
app.use(router)

// Templates
app.component('default', Tdefault)

// Components
app.component('ta-navbar', Navbar)
app.component('ta-footer', Footer)
app.component('ta-modal', Modal1)
app.component('ta-modal2', Modal2)

// Libs


// Mount
app.mount('#app')