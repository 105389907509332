<template>
    <footer class="to-footer flex-center"
    style="background-repeat: space; background-size: cover; background-position: bottom left;">
        <div class="w-100 mw-footer">

            <div class="row flex-between-start pb-sm-5 mb-4" 
            style="border-bottom: 1px solid #687068;">

                <div class="col-lg-12 col-xl-3 mb-5">
                    <a class="mb-5 imghover " @click="navigateToHome">
                        <img :src="icon_tony" class="img-fluid mb-5" alt="logo tony almazan" width="294" height="47" loading="lazy">
                    </a>

                    <div class="flex-start">
                        <a href="https://www.facebook.com/tonyalmazanofficial" target="_blank">
                            <div class="mx-2 imghover" style="width: 16px;">
                                <img :src="icon_fb" class="img-fluid" width="35" height="35" alt="facebook logo" loading="lazy">
                            </div>
                        </a>
                        <a href="https://www.youtube.com/user/inversiones123web" target="_blank">
                            <div class="mx-2 imghover" style="width: 16px;">
                                <img :src="icon_yt" class="img-fluid" width="35" height="35" alt="youtube logo">
                            </div>
                        </a>
                        <a href="https://www.instagram.com/tonyalmazanofficial/" target="_blank">
                            <div class="mx-2 imghover" style="width: 16px;">
                                <img :src="icon_ig" class="img-fluid" width="35" height="35" alt="instagram logo">
                            </div>
                        </a>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3 col-xl-2 mb-4 mb-lg-0">
                    <div class="title">
                        <span class=" playfair-bold text-warning">
                            Contacto
                        </span>
                    </div>

                    <div class="mt-3 mb-2">
                        <span class="text-warning">
                            Dirección:
                        </span> <br>
                        <span class="text-white navicon">
                            3251 w 6th st ste 400A Los Angeles, CA 90020
                        </span>
                    </div>
                     
                    <div class="my-2">
                        <span class="text-warning">
                            Celular:
                        </span> <br>
                        <span class="text-white navicon">
                            (323) 770 9683
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="text-warning">
                            E-mail:
                        </span> <br>
                        <span class="text-white navicon">
                            contacto@tonyalmazan.com
                        </span>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3 col-xl-2 mb-4 mb-lg-0">
                    <div class="title">
                        <span class="playfair-bold text-warning navicon">
                            Información
                        </span>
                    </div>

                    <div class="mt-3 mb-2">
                        <span class="text-white navicon">
                            F.A.Q.
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="text-white navicon" @click="navigateToTerms">
                            Términos y condiciones
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="text-white navicon">
                            Política de privacidad
                        </span>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3 col-xl-2 mb-4 mb-lg-0">
                    <div class="title">
                        <span class="playfair-bold text-warning navicon">
                            Links Rapidos
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="text-white navicon" @click="navigateToContact">
                            Contáctanos
                        </span>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-3 col-xl-2">
                    <a class="mx-2 imghover " @click="navigateToHome">
                        <!--<img :src="tonygold" class="img-fluid" style="">-->
                    </a>
                </div>
            </div>

            <div class="w-100 text-center">
                <span class="text-white">
                    Copyright © 2022 © Éxito Financiero, LLC. Todos los derechos reservados.    
                </span>
            </div>
        </div>
        
    </footer>
</template>

<script setup>
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    const navigateToContact = () => {
        router.push('/contact');
        window.scrollTo(0, 0); 
    }
    const navigateToTerms = () => {
        router.push('/terms');
        window.scrollTo(0, 0); 
    }
    const navigateToHome = () => {
        router.push('/');
        window.scrollTo(0, 0); 
    }

// Imágenes
import icon_tony from 'ASSETS/images/tonygold'
import icon_fb from 'ASSETS/icons/facebook'
import icon_yt from 'ASSETS/icons/youtube'
import icon_ig from 'ASSETS/icons/instagram'
import line2 from 'ASSETS/images/lineas2'
import logomn from 'ASSETS/images/logo'

const data = {
  icon_tony,
  icon_fb,
  icon_yt,
  icon_ig,
  line2,
  logomn
}
</script>

<style lang="scss" scoped>
    @import '../assets/scss/minxin.scss';

    .to-footer {
        background-color: #000000;
        padding-top: 100px;
        padding-bottom: 80px;

        font-family: Arial;
        font-size: 14px;
        font-weight: 500;


        .title {
            font-size: 20px;
            border-bottom: 1px solid white;
            padding-bottom: 10px;
        }
        .imghover:hover{
        transform: scale(1.15);
        cursor: pointer;
        } 

        .mw-footer {
            max-width: 300px;

            @include upPixels(460px) {
                max-width: 400px;
            }
            @include upPixels(576px) {
                max-width: 520px;
            }
            @include upPixels(768px) {
                max-width: 600px;
            }
            @include upPixels(992px) {
                max-width: 820px;
            }
            @include upPixels(1200px) {
                max-width: 1170px;
            }
        }
    }
    .navicon{
        transform: scale(1.0);

    } 
    .navicon:hover{
        transform: scale(1.2);
        cursor: pointer;
    } 
</style>