<template>
    <ta-navbar></ta-navbar>
    <div class="container-fluid" style="background-color: #242424;">
        <div class="row d-flex justify-content-around align-items-center p-sm-5 mx-md-5 py-3">
            <div class="col-12 mt-5 pt-5 ">
                <div class="mx-2 row d-flex justify-content-center">    
                    <a @click="" href="tel:+13237709683"
                        class="btn btn-warning px-md-5 px-1 text-white title-sm buttonshine">
                        CONTÁCTANOS PARA MAS INFORMACIÓN
                        <br>
                    </a>
                </div>
            </div>
            <div class="col-12 text-center">
                <div class="my-5">    
                    <span class="description-md text-white">
                        completa el siguiente formulario con tu información y nos pondremos en contacto contigo lo antes posible.
                    </span>
                </div>
            </div>
            <div class="col-12 mb-sm-5 mb-4 py-3 bg-black" v-show="!sended_email" style="border-radius: 25px;">
                <div class="row justify-content-between">
                    <div class="flex-between-center h-100">
                        <div class="flex-start h-100">
                            <img :src="tonylogo" class="img-fluid imghover mx-2" style="max-height: 70px;">
                        </div>
                        <div class="flex-center-center h-100 ms-md-5 ms-3" >
                            <a href="https://www.facebook.com/tonyalmazanofficial" target="_blank">
                                <div class="mx-md-3 mx-2 imghover" style="width: 35px;">
                                    <img :src="icon_fb" class="img-fluid">
                                </div>
                            </a>
                            <a href="https://www.youtube.com/user/inversiones123web" target="_blank">
                                <div class="mx-md-3 mx-2 imghover" style="width: 35px;">
                                    <img :src="icon_yt" class="img-fluid">
                                </div>
                            </a>
                            <a href="https://www.instagram.com/tonyalmazanofficial/" target="_blank">
                                <div class="mx-md-3 mx-2 imghover" style="width: 35px;">
                                    <img :src="icon_ig" class="img-fluid ">
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-12">
                        <label class="form-label text-white" for="typeText"></label>
                        <input v-model="name" type="text" placeholder="Nombre" class="form-control mb-1 description-xs" :class="{ 'is-invalid': !isNameValid }" />
                        <div v-if="!isNameValid" class="invalid-feedback text-warning">Por favor ingrese al menos 3 carácteres para el nombre.</div>
                    </div>
                    <div class="col-md-6 col-12">
                        <label class="form-label text-white" for="typeNumber"></label>
                        <input v-model="phone" type="number" placeholder="Móvil" class="form-control mb-1 description-xs" :class="{ 'is-invalid': !isPhoneValid }" max="10"/>
                        <div v-if="!isPhoneValid" class="invalid-feedback text-warning">El número de teléfono debe tener al menos 10 dígitos.</div>
                    </div>
                    <div class="col-md-6 col-12">
                        <label class="form-label text-white" for="typeEmail"></label>
                        <input v-model="email" type="email" placeholder="Email" class="form-control mb-1 description-xs" :class="{ 'is-invalid': !isEmailValid }" />
                        <div v-if="!isEmailValid" class="invalid-feedback text-warning">Por favor ingrese un correo electrónico válido (ejemplo@xmail.com).</div>
                    </div>
                    </div>
                    <div class="form-outline">
                        <label class="form-label text-white" for="textAreaExample"></label>
                        <textarea v-model="message" class="form-control description-xs" placeholder="Mensaje" rows="4"></textarea>
                        </div>
                    <div class="col-md-6 col-12 mt-3">
                    <button @click="sendMail()" class="btn btn-warning px-5 text-white w-100 buttonshine" :disabled="!isFormValid || buttonDisabled">
                        ENVIAR
                    </button>
                    <span class="montserrat-light text-white" style="font-size: 12px;">
                        Al “dar clic en enviar” aceptas nuestros términos
                        y condiciones de privacidad.
                    </span>
                </div>
            </div>
            <div class="col-12 my-5 py-5 border border-light" v-show="sended_email">
                <div class="description-md text-warning montserrat-semibold text-center">
                    Gracias por compartir tu información
                </div>
                <div class="description-md montserrat-semibold text-white text-center">
                    Pronto nos comunicamos contigo 
                </div>        
            </div>

            <div class="col-12 flex-center align-self-center">
                <span class="text-warning description-lg mx-4">
                    <b>CONTACTO</b>
                </span>
            </div>
                <div class="mb-md-4 mb-3 mt-4">
                    <span class="montserrat-light description-md text-white"><img :src="icon3" class="me-4">
                        3251 w 6th st ste 400A Los Ángeles, CA 90020
                    </span>
                </div>
                <div class="mb-md-4 mb-3 text-start">
                <img :src="icon2" class="me-4">
                    <a @click="" href="tel:+13237709683"
                        class="btn btn-warning px-1 text-white description-md buttonshine">
                        (323) 770 9683 
                    </a>
                </div>
                <div class="mb-md-4 mb-3">
                    <span class="montserrat-light description-md text-white"><img :src="icon1" class="me-4">
                        contacto@tonyalmazan.com
                    </span>
                </div>
            <div class="col-12">
                <div class="mb-3 text-center">    
                    <span class="description-sm text-white">
                        ¡Contáctanos en cualquier momento! Nos esforzamos diariamente por responder a todas las consultas dentro de las 24 horas en días hábiles. Estamos encantados de responder a tus preguntas.
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ta-footer></ta-footer>
</template>
<script setup>
    import { ref, computed } from 'vue';

    
    const icon1 = require("ASSETS/icons/IconEmail")
    const icon2 = require("ASSETS/icons/IconPhone")
    const icon3 = require("ASSETS/icons/IconMaps")

    const iconH = require("ASSETS/icons/handClick")

    const icon_fb = require("ASSETS/icons/facebookGold")
    const icon_yt = require("ASSETS/icons/youtubeGold")
    const icon_ig = require("ASSETS/icons/instagramGold")

    const tonylogo = require("ASSETS/images/logo")

    const mncart = require("ASSETS/images/cart/mncart")

    const name = ref('')
    const phone = ref('')
    const email = ref('')
    const message = ref('')
    const sended_email = ref(false);
    const buttonDisabled = ref(false);

    /*const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };*/
    const isNameValid = computed(() => name.value.length > 3);
    const isPhoneValid = computed(() => phone.value.toString().length >= 10);
    const isEmailValid = computed(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value));
    const isFormValid = computed(() => isNameValid.value && isPhoneValid.value && isEmailValid.value);

    const sendMail = () => {
  if (!buttonDisabled.value && isFormValid.value) {
    buttonDisabled.value = true;
    setTimeout(() => {
          sended_email.value = true;
        }, 2000);
        
    let body = JSON.stringify({ 
      name: name.value, 
      phone: phone.value, 
      email: email.value,
      message: message.value 
    });

    fetch('https://tonyalmazan.com/send_email.php', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, /',
        'Content-Type': 'application/json'
      },
      body
    }).then(res => {
      console.log(res, 'RES');
      if (res.status == 200) {
        //setTimeout(() => {
        //}, 1000);
      } else {

      }
    }).catch(error => {
      console.error('Error:', error);

    });
  }
};
</script>
<style lang="sscs" scoped>
    
</style>