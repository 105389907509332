<template>
    <ta-navbar></ta-navbar>
    <div class="container-fluid" style="background-color: #242424;">
        <div class="row align-items-start mx-3">
            <div class="col-12 mt-5 py-5 d-flex justify-content-end">
                <ta-modal2>
            </ta-modal2>
            </div>
            <div class="col-12 col-lg-5 d-flex justify-content-around flex-column">
                <div class="mb-3 text-center">    
                    <span class="title-md text-white">
                        Videocurso
                    </span>
                </div>
                <div class="bg-warning rounded border border-white mx-sm-4">
                    <div class="m-3 mt-2 text-center">
                        <span class="text-hov description-xs text-white text-uppercase " style="font-weight: 800;"> 
                            <b class="text-white description-xs " style="font-size: 10pt">
                                INFORMACIÓN PARA ANTES DE COMPRAR                         
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            El curso es completamente en línea. Tienes un año para completarlo, de no ser así deberás renovar tu compra.                     
                        </span>
                    </div>
                </div>
                <div class="my-3 text-center">
                    <span class="montserrat-light description-xs text-white ">
                        Tu guía práctica a la riqueza y libertad financiera con Tony Almazan.
                    </span>
                </div>
                <div class="mb-3 d-flex justify-content-center">
                    <button type="button" class="btn btn-warning buttonshine text-center"  @click="$router.push('/shopcart')" style="border: 4px solid #D29751;">
                        <span class="text-white description-xs mx-4">
                            COMPRAR VIDEO CURSO
                        </span>
                    </button>
                </div>

                <div class="my-3 text-start">
                    <span class="montserrat-light description-sm text-white ">
                        VIDEOCURSO
                    </span>
                    <br>
                    <span class="montserrat-light description-xs text-white ">
                        ESPECIFICACIONES DEL CURSO
                    </span>
                </div>
                <div class="my-3 text-start">
                    <span class="montserrat-light description-xs text-white">
                        <img :src="icon_vc1" class="img-fluid mx-2" style="width: 25px;">No. de Modulos: 12
                    </span>
                    <br>
                    <span class="montserrat-light description-xs text-white ">
                        <img :src="icon_vc2" class="img-fluid m-2" style="width: 25px;">Duración de curso: 5 horas
                    </span>
                    <br>
                    <span class="montserrat-light description-xs text-white ">
                        <img :src="icon_vc3" class="img-fluid m-2" style="width: 25px;">Idioma: Español
                    </span>
                    <br>
                    <span class="montserrat-light description-xs text-white ">
                        <img :src="icon_vc4" class="img-fluid m-2" style="width: 25px;">Nivel: Cualquier Nivel
                    </span>
                    <br>
                    <span class="montserrat-light description-xs text-white ">
                        <img :src="icon_vc5" class="img-fluid m-2" style="width: 25px;">Diploma: Certificado digital
                    </span>
                </div>
                <div class="bg-warning rounded border border-white m-4">
                    <div class="m-4 mt-2 text-center">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white ">
                                ¡Compra el videocurso en la comodidad de tu casa con unos rápidos clicks!                        
                            </b>
                        </span>
                        <br>
                        <br>
                        <span class="montserrat-light text-white my-5">  
                                O si lo prefieres, comunícate con nosotros en un horario de 7:00 am a 6:00 pm PST* de Lunes a viernes, o de 8:00 am a 1:00 pm PST los Sábados.                            
                        </span>
                        <br>
                        <span class="description-xs text-white">
                            COSTO:                        
                        </span>
                        <br>
                        <span class="description-xs text-danger text-decoration-line-through">
                            $650.00                        
                        </span>
                        <br>
                        <span class="text-hov description-sm text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white">
                                $299.00 USD                            
                            </b>
                        </span>
                        <br>
                        <button type="button" class="btn buttonshine text-center rounded my-3"  style="background-color: #687068;" @click="$router.push('/shopcart')">
                            <span class="text-white description-xs mx-4" style="font-size: 10pt">
                                COMPRAR TU CURSO
                            </span>
                        </button>
                    </div>
                </div>    
            </div>

            <div class="col-12 col-lg-7 mb-5">
                <img :src="picsix1" class="img-hov" style="width:100%;">
            </div>
        </div>
    </div>
        <!--////////////////////////////////////////////////////////////////////////////////////////////-->
    <div class="container-fluid" style="background-color: #141414;">
        <div class="row d-flex justify-content-around align-items-start p-md-5 p-3 mx-lg-5">
            <div class="col-12 text-center my-md-4 py-lg-4 mt-5">
                <div class="mb-4">
                    <span class="text-hov description-xs text-white mx-md-4 my-4 text-uppercase" style="font-weight: 800; text-shadow: 7px 7px 7px #000000;"> 
                        <b class="text-warning description-md">
                            QUÉ APRENDERÁS
                        </b>
                    </span>
                </div>
                <div class="mx-md-5 px-lg-5">
                    <span class="montserrat-light description-xs text-white">
                        El objetivo de este curso es enseñar a los participantes como, cuando, cuanto y donde invertir su dinero. Las personas que se
                        educan con nosotros terminan este curso, y lo llevan a la práctica, progresivamente empezarán a percibir ganancias desde el
                        primer día de inversión. Pero la idea, es no tomar las ganancias para que estas a su vez generen más ganancias, creando de
                        esta manera el interés compuesto que ha hecho ricas a muchas personas.                    
                    </span>
                </div>
            </div>
            <!--////////////////////////////////////////////////////////////////////////////////////////////-->
            <div class="col-lg-3 col-6 text-start mt-5 box1-hov">
                <div class="imghover" style="width: 55px;">
                    <img :src="icon_book" class="img-fluid">
                </div>
                <div class="mb-1 mt-2">
                    <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800; "> 
                        <b class="text-warning description-xs" style="font-size: 10pt">
                            ANÁLISIS FUNDAMENTAL Y TÉCNICO
                        </b>
                    </span>
                </div>
                <div class="">
                    <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                        Para mantener nuestro dinero con poco riesgo y percibiendo ganancias que 
                        multipliquen sistemáticamente nuestro capital invertido.                   
                    </span>
                </div>
            </div>
            <div class="col-lg-3 col-6 text-start mt-5 box1-hov">
                <div class="imghover" style="width: 50px;">
                    <img :src="icon_money" class="img-fluid">
                </div>
                <div class="mb-1 mt-2">
                    <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                        <b class="text-warning description-xs" style="font-size: 10pt">
                            OBTENER UN INGRESO PASIVO
                        </b>
                    </span>
                </div>
                <div class="">
                    <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                        Este sistema no es para enriquecerse rápidamente, pero sí, en un promedio de 5 a 10 años podrás tener un ingreso pasivo que irá de acuerdo al monto invertido que le proporcionará no solo ingreso
                        residual, sino, tiempo libre, logrando de esta manera su Independencia Financiera.                  
                    </span>
                </div>
            </div>
            <div class="col-lg-3 col-6 text-start mt-5 box1-hov">
                <div class="imghover" style="width: 50px;">
                    <img :src="icon_pig" class="img-fluid">
                </div>
                <div class="mb-1 mt-2">
                    <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                        <b class="text-warning description-xs" style="font-size: 10pt">
                            SISTEMA DE AHORRO
                        </b>
                    </span>
                </div>
                <div class="">
                    <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                        Las personas podrán invertir la cantidad que deseen con la diferencia que ese dinero se invertirá en las empresas más grandes y poderosas de Estados Unidos, 
                        consiguiendo un rendimiento en porcentaje más alto que cualquier banco comercial pueda ofrecer, y lo más importante es que el dinero y la
                        cuenta de inversión nunca estará en manos de terceros, teniendo control así de sus ganancias y el capital.                  
                    </span>
                </div>
            </div>
            <div class="col-lg-3 col-6 text-start mt-5 box1-hov">
                <div class="imghover" style="width: 55px;">
                    <img :src="icon_check" class="img-fluid">
                </div>
                <div class="mb-1 mt-2">
                    <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                        <b class="text-warning description-xs" style="font-size: 10pt">
                            AL TERMINAR EL CURSO
                        </b>
                    </span>
                </div>
                <div class="">
                    <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                        Tendrás la capacidad de crear un plan financiero y podrá hacer sus inversiones por ella misma, 
                        evitando altos pagos en comisiones a terceros y manteniendo toda la ganancia.                  
                    </span>
                </div>
            </div>
        </div>
        </div>
        <!--/////////////////////////////////////////PARA QUIÉN ES EL CURSO///////////////////////////////////////////////////-->
            <div class="container-fluid" style="background-color: #141414;">
            <div class="row mx-lg-5 h-100">
                <div class="col-12 text-center mt-md-2 pt-lg-4 mt-3">
                    <div class="mb-4">
                        <span class="text-hov description-xs text-white mx-md-4 my-4 text-uppercase" style="font-weight: 800; text-shadow: 7px 7px 7px #000000;"> 
                            <b class="text-warning description-md">
                                PARA QUIÉN ES EL CURSO
                            </b>
                        </span>
                    </div>
                    <div class="mx-md-5 px-md-5 pb-5">
                        <span class="montserrat-light description-xs text-white">
                            Esto es exclusivamente para aquellos que siempre han querido invertir su dinero por sí mismos.                 
                        </span>
                    </div>
                 </div>
                  <!--////////////////////////////////////////////////////////////////////////////////////////////-->
                <div class="col-lg-6 col-12 text-start position-relative h-div" style="height: 360px;">
                    <div class="bg-warning rounded border border-white w-75 boxmove" >
                        <div class="m-3 mt-2">
                            <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                                <b class="text-white description-xs" style="font-size: 10pt">
                                    INVERSIÓN DE TIEMPO                            
                                </b>
                            </span>
                            <br>
                            <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                                En el transcurso de 5 horas que dura el curso aprenderemos de la A a la Z como convertirse en un inversionista exitoso.                 
                            </span>
                        </div>
                    </div>
                    <div class="bg-warning rounded border border-white my-4 w-75 position-absolute bottom-0 end-0 mx-3 boxmove2">
                        <div class="m-3 mt-2">
                            <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                                <b class="text-white description-xs" style="font-size: 10pt">
                                    REQUISITOS                           
                                </b>
                            </span>
                            <br>
                            <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                                Mínimo conocimiento en el manejo de internet y
                                un monto de inversión cada mes que la persona
                                misma determinará según su situación
                                financiera actual. Entendiendo que entre más
                                metes, más puedes sacar. Pero no es necesario
                                morir de hambre para hacerlo.                 
                            </span>
                        </div>
                    </div>
                </div>
                <!--////////////////////////////////////////////////////////////////////////////////////////////-->
            <div class="col-lg-6 col-12 text-start mt-lg-5 position-relative h-div" style="height: 380px;">
                <div class="bg-warning rounded border border-white w-75 mx-3b-5 boxmove">
                    <div class="m-3 mt-2">
                        <span class="text-hov description-xs text-white text-uppercase " style="font-weight: 800;"> 
                            <b class="text-white description-xs text-start" style="font-size: 10pt">
                                PLAN FINANCIERO                          
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Crearemos un plan financiero a la medida de
                            cada uno, donde determinaremos la inversión y
                            la ganancia futura que podríamos percibir. Para
                            aprovechar este curso es necesario contar con
                            una fuente de ingresos ya que no es para ganar
                            dinero rápido, sino eventualmente vivir de
                            nuestro capital.                        
                        </span>
                    </div>
                </div>
                <div class="rounded border border-white my-5 me-5 position-absolute bottom-0 end-0 w-75 boxmove2" style="background-color: #ff0303;">
                    <div class="m-3 mt-2">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                QUIEN NO APROVECHARÍA ESTE CURSO:                        
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Los que buscan ganancias rápidas o
                            estrategias especulativas.                      
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--////////////////////////////////////////////////////////////////////////////////////////////-->
    <div class="container-fluid" style="background-color: #242424;">
        <div class="row d-flex justify-content-around align-items-start p-md-5 p-3 mx-lg-5">
            <div class="col-12 text-center my-md-4 py-lg-4 mt-5">
                <div class="mb-4 mt-4">
                    <span class="text-hov description-xs text-white mx-md-4 my-4 text-uppercase" style="font-weight: 800; text-shadow: 7px 7px 7px #000000;"> 
                        <b class="text-warning description-md">
                            REQUISITOS
                        </b>
                    </span>
                </div>
                <div class="px-md-5 mx-ls-5 text-start">
                    <span class="montserrat-light description-xs text-white" style="font-size: 11pt">
                        <img :src="icon_check2" class="img-fluid m-1">Número tributario de acuerdo al país donde se encuentre. Los que residen en Estados Unidos podrán usar SSN ó ITIN.                 
                    </span>
                    <br>
                    <span class="montserrat-light description-xs text-white" style="font-size: 11pt">
                        <img :src="icon_check2" class="img-fluid m-1"> Necesitarás conocimientos básicos de manejo del internet (si sabes utilizar alguna red social es suficiente).                 
                    </span>
                    <br>
                    <span class="montserrat-light description-xs text-white" style="font-size: 11pt">
                        <img :src="icon_check2" class="img-fluid m-1">  Identificación oficial vigente con foto.                 
                    </span>
                </div>
            </div>
                <!--////////////////////////////////////////////////////////////////////////////////////////////-->
            <div class="row d-flex justify-content-center align-items-start p-2 mx-lg-5">
                <div class="col-12 text-center">
                    <div class="my-lg-1 my-4">
                        <span class="text-hov description-xs text-white mt-4 text-uppercase" style="font-weight: 800; "> 
                            <b class="text-warning description-md">
                                OBTÉN TU VIDEOCURSO HOY, EN DONDE APRENDERÁS
                            </b>
                        </span>
                    </div>
                </div>
            </div>    
                <!--////////////-->
            <div class="row d-flex mx-lg-3 px-lg-3 w-75">
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                01 Como establecer mi meta financiera                          
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Ganarás la capacidad de crear un plan financiero a tu medida.            
                        </span>
                    </div>
                </div>    
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                02 La fórmula del dinero                           
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Aprenderás un método de inversión llamado Position Trading, el cual nos da ganancias por meses o años.                 
                        </span>
                    </div>
                </div>  
                <!--////////////-->
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                03 El interes. Como encontrar y crear mi riqueza                          
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Entenderás el interés compuesto y como podrás aprovecharlo.                
                        </span>
                    </div>
                </div>    
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                04 Como funciona la bolsa de valores                          
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Comprenderás como funciona la bolsa de valores eliminando estigmas sobre ella.                
                        </span>
                    </div>
                </div>  
                <!--////////////-->  
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                05 Como participar en la bolsa                        
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Aprenderás el tripié financiero para comenzar a invertir y ganar en la bolsa.             
                        </span>
                    </div>
                </div>    
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                06 Como funcionan los bancos bursátiles y como abrir una cuenta                          
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Sabrás como utilizar los bancos bursátiles de descuento, como funcionan las cuentas de inversión y como abrir una cuenta de inversión.                
                        </span>
                    </div>
                </div>  
               
                <!--////////////--> 
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                07 Como ganar dinero de manera segura                          
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Aprenderás la relación de ganancia y riesgo (risk and reward) que existe en la bolsa de valores y utilizarla a tu favor.               
                        </span>
                    </div>
                </div>    
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                08 Como ganar cuando el mercado cae                           
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Desarrollarás una estrategia para minimizar el riesgo y maximizar las ganancias, aprovechando las subidas y bajadas normales de los mercados bursátiles.                
                        </span>
                    </div>
                </div> 
     <!--////////////////////////////////////////////////////////////////////////////////////////////-->
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                09 Como y donde invertir                          
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Aprenderás el análisis fundamental (capacidad para entender y analizar los instrumentos donde vamos a invertir), como el análisis técnico (estudio de gráficas para saber el momento adecuado de invertir).                
                        </span>
                    </div>
                </div>    
                <div class="col-lg-6 col-12 text-start my-1">
                    <div class="m-1 mb-2 p-3 box-hov w-100 h-100" style="border: 3px solid #D29751;">
                        <span class="text-hov description-xs text-white text-uppercase" style="font-weight: 800;"> 
                            <b class="text-white description-xs" style="font-size: 10pt">
                                10 Como comprar y vender                          
                            </b>
                        </span>
                        <br>
                        <span class="montserrat-light description-xs text-white" style="font-size: 10pt">
                            Aprenderás a comprar y vender por ti mismo las acciones a través de una demostración gráfica en un banco real.                 
                        </span>
                    </div>
                </div>   
                
                <!--////////////////////////////////////////////////////////////////////////////////////////////-->
                <div class="col-12 text-center mt-md-4 pt-lg-4">
                    <div class=" mt-4">
                        <span class="text-hov description-xs text-white mx-md-4 mt-4 text-uppercase" style="font-weight: 800; "> 
                            <b class="text-white description-md">
                                Compra tu videocurso
                            </b>
                        </span>
                        <br>
                        <button type="button" class="btn btn-warning m-3 buttonshine "  style="border: 4px solid #D29751;" @click="$router.push('/contact')">
                            <span class="text-white description-xs mx-4">
                                LLÁMANOS
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    <ta-footer></ta-footer>
</template>
<script setup>
    const icon_book = require("ASSETS/icons/IconBook") 
    const icon_money = require("ASSETS/icons/IconMoney") 
    const icon_pig = require("ASSETS/icons/IconPig") 
    const icon_check = require("ASSETS/icons/IconCheck") 

    const icon_check2 = require("ASSETS/icons/IconCheck2") 

    const icon_padlock = require("ASSETS/icons/padlock")

    const picsix1 = require("ASSETS/images/course/mnpic")

    const icon_vc1 = require("ASSETS/icons/vc1") 
    const icon_vc2 = require("ASSETS/icons/vc2") 
    const icon_vc3 = require("ASSETS/icons/vc3") 
    const icon_vc4 = require("ASSETS/icons/vc4") 
    const icon_vc5 = require("ASSETS/icons/vc5") 
    
    
</script>
<style lang="scss" scoped>
    .box1-hov:hover {
        transform: scale(1.01);
        transition: .3s ease;
        box-shadow: 3px 3px #D29751;
        text-shadow: 3px 3px #323232;
    }
    .box-hov:hover {
        transform: scale(1.04);
        transition: .5s ease;
        background-color: rgb(57, 57, 57);
    }
    .boxmove:hover {
            animation: desplazar 4s linear infinite; /* Ajusta la cantidad de desplazamiento a la derecha */

        }
        @keyframes desplazar {
                0%, 100% {
                    transform: translateX(0); /* Estado inicial y final, sin desplazamiento */
                    transform: scale(1.06);
                    box-shadow: 5px 5px 3px #000000;
                }
                50% {
                    transform: translateX(25%); /* Desplazamiento a la derecha en la mitad de la animación */
                }
        }
    .boxmove2:hover {
        
        animation: desplazar2 4s linear infinite; /* Ajusta la cantidad de desplazamiento a la derecha */

    }
    @keyframes desplazar2 {
        
            0%, 100% {
                transform: translateX(0); /* Estado inicial y final, sin desplazamiento */
                transform: scale(1.06);
                box-shadow: 5px 5px 3px #000000;
            }
            50% {
                transform: translateX(-25%); /* Desplazamiento a la derecha en la mitad de la animación */
            }
    }


    /* Media query para pantallas con un ancho menor a 992px */
    @media (max-width: 992px) {
        .h-div {
            height: 310px !important; /* Nueva altura para pantallas más pequeñas */
        }
    }
    .navicon:hover{
        transform: scale(1.3);
        cursor: pointer;
    } 
</style>