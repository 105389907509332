<template>
    <div class="" style="background-color: #242424;">
    <ta-navbar></ta-navbar>
    <!--////////////////////////////////////////////////////////////////////////////////////////////-->
    <section class="container-fluid" 
    style="background-repeat: space; background-size: cover; background-position: bottom right; background-color: #0c0c0c; box-shadow: 0px 20px 40px #0a0a0a;" >
        <div class="row flex-center px-lg-5 mx-sm-5 h-100">
            <div class="col-12 flex-center align-self-center mx-md-4 mx-1 px-md-5 mt-5 pt-4">
                <img :src="mnpic" class="img-hov img-fluid mt-1" style="max-height: 100%; max-width: 100%;" width="1361" height="711" alt="tony almazan principal">
            </div>
            <div class="col-12 flex-center align-self-center my-sm-4 pt-lg-5">
                    <h1 class="text-hov description-md text-white text-center mx-md-3 my-4 text-uppercase" style="font-weight: 1000; line-height: 1.8;">
                            Con mi estrategia de aumento sustancial de ingresos, 
                        <b class="text-warning">
                            te enseñaré a paso cómo hacer sus propias inversiones
                        </b>  
                            con la bolsa de valores de Nueva York de manera sencilla 
                            y práctica para que puedas                         
                        <b class="text-warning">
                            ganar el dinero que necesitas 
                            para cumplir todos tus sueños.
                        </b>  
                    </h1>
            </div>
            <button type="button" class="btn btn-warning mb-5 buttonshine w-50 text-center wbtn"  style="border: 4px solid #D29751;" @click="navigateToContact">
                <h3 class="text-white description-lg ">
                    <b>MÁS INFORMACIÓN</b>
                </h3>
            </button>
        </div>
    </section>    
    <!--////////////////////////////////////////////////////////////////////////////////////////////-->
    <section class="container-fluid"
    style="background-repeat: space; background-size: cover; background-position: bottom right;" >
            <div class="row mx-md-5 mx-3 mb-md-4">
                <div class="col-12 flex-center align-self-center my-md-4 py-lg-4">
                    <h2 class="text-hov description-lg text-white text-center mx-md-4 my-4 text-uppercase" style="font-weight: 800; text-shadow: 7px 7px 7px #000000;"> 
                        ¿QUIERES CONOCER MáS ACERCA DE MI ESTRATEGIA,<br>

                        <b class="text-warning">
                            HAS CLICK EN EL SIGUIENTE VIDEO?
                        </b>
                    </h2>
                </div>
                <div class="col-12 flex-center align-self-center mx-md-3 mx-1 pb-5">
                    <video class="w-100" v-on:click="toggleVideo" @play="handlePlay" @pause="handlePause" ref="myVideo" controls :poster="mnpic22">
                        <source :src="videomn" type="video/mp4">
                        Tu navegador no soporta el elemento de video.
                    </video>
                </div>
            </div>
            <div class="row">
                <div class="col-12 flex-center align-self-center" style="height: 30px; background-color: #ffffff; box-shadow: 0px 5px 15px #000;">
                    <button type="button" class="btn btn-warning m-3 buttonshine"  style="border: 4px solid #D29751;" @click="navigateToContact">
                        <h3 class="text-white title-md mx-4">
                            LLÁMANOS
                        </h3>
                    </button>
                </div>
            </div>

        <div class="row d-flex pt-5 mx-lg-5 px-4">
            <div class="col-md-4 col-sm-6 col-12 flex-center flex-column mb-4">
                <div class="m-sm-4 m-2 pb-sm-3">
                    <img :src="picsix1" class="img-fluid imghover" alt="clases de finanzas tony almazan" width="550" height="550" loading="lazy"> 
                </div>    
            </div>
            <div class="col-md-4 col-sm-6 col-12 flex-center flex-column border-start border-end mb-4">
                <div class="m-sm-4 m-2 pb-sm-3">
                    <img :src="picsix2" class="img-fluid imghover" alt="clases de finanzas tony almazan" width="550" height="550" loading="lazy">  
                </div>      
            </div>
            <div class="col-md-4 col-sm-6 col-12 flex-center flex-column mb-4">
                <div class="m-sm-4 m-2 pb-sm-3">
                    <img :src="picsix3" class="img-fluid imghover" alt="clases de finanzas tony almazan" width="550" height="550" loading="lazy"> 
                </div>   
            </div>
            <div class="col-md-4 col-sm-6 col-12 flex-center flex-column mb-4">
                <div class="m-sm-4 m-2">
                    <img :src="picsix4" class="img-fluid imghover" alt="clases de finanzas tony almazan" width="550" height="550" loading="lazy"> 
                </div>    
            </div>
            <div class="col-md-4 col-sm-6 col-12 flex-center flex-column border-start border-end mb-4">
                <div class="m-sm-4 m-2">
                    <img :src="picsix5" class="img-fluid imghover" alt="clases de finanzas tony almazan" width="550" height="550" loading="lazy">  
                </div>      
            </div>
            <div class="col-md-4 col-sm-6 col-12 flex-center flex-column mb-4">
                <div class="m-sm-4 m-2">
                    <img :src="picsix6" class="img-fluid imghover" alt="clases de finanzas tony almazan" width="550" height="550" loading="lazy"> 
                </div>   
            </div>
        </div>
    </section>

    <!--////////////////////////////////////////////////////////////////////////////////////////////--> 
    <!--////////////////////////////////////////////////////////////////////////////////////////////--> 
    <section class="container-fluid"
    style="background-repeat: space; background-size: cover;  background-position: bottom right;">
            <div class="row mx-5 h-100">
                <div class="col-12 flex-center align-self-center mt-sm-5 mt-1">
                    <span class="text-hov description-lg text-white text-center mx-lg-5 mb-4 text-uppercase" 
                    style="font-weight: 800; text-shadow: 7px 7px 7px #000000;"> 
                        Si quieres que nos comuniquemos contigo
                        <br>
                    </span>
                </div>
                <div class="col-12 flex-center align-self-center text-center">
                    <button type="button" class="btn buttonshine"  style="border: 4px solid #D29751;" @click="navigateToContact">
                        <span class="text-white description-lg">
                            <b>DEJA TU INFORMACIÓN</b>
                        </span>
                    </button>
                </div>
                <div class="col-12 flex-center align-self-center" >
                    <span class="text-hov description-lg text-warning text-center mx-lg-5 my-4 text-uppercase" 
                    style="font-weight: 800; text-shadow: 7px 7px 7px #000000;"> 
                        Clases en vivo y en directo 
                        <br>
                    </span>
                </div>
            </div>
    </section>
</div>
<!--////////////////////////////////////////////////////////////////////////////////////////////--> 
<ta-footer></ta-footer>
</template>

<script setup>
    // Images
    //require name_imagen ('')

        //navbar
    const icon = require("ASSETS/icons/search")    
    const icon1 = require("ASSETS/icons/facebook")
    const icon2 = require("ASSETS/icons/youtube")
    const icon3 = require("ASSETS/icons/instagram")
        //MnFrontSection
    const logomn = require("ASSETS/images/logo") 
    const mnpic = require("ASSETS/images/tonymn") 
    const mnpic22 = require("ASSETS/images/tonymn3") 
    const mn2pic = require("ASSETS/images/mnimg") 
    const mnpic2 = require("ASSETS/images/mnsection2") 
    const videomn = require("ASSETS/videos/tonymnvideo.mp4") 
        //bluelabel
    const iconBlue1 = require("ASSETS/icons/blue1")
    const iconBlue2 = require("ASSETS/icons/blue2")
    const iconBlue3 = require("ASSETS/icons/blue3")
    const iconBlue4 = require("ASSETS/icons/blue4")
        //sixpic
    const picsix1 = require("ASSETS/images/six1")
    const picsix2 = require("ASSETS/images/six2")
    const picsix3 = require("ASSETS/images/six3")
    const picsix4 = require("ASSETS/images/six4")
    const picsix5 = require("ASSETS/images/six5")
    const picsix6 = require("ASSETS/images/six6")
        //////
    const bgmn = require("ASSETS/images/mnbg") 
        /////
    const line3 = require("ASSETS/images/lineas3")
    const line2 = require("ASSETS/images/lineas2")    
    


    
    // Vue Imports
    import { Footer, Navbar } from '@/components';
    import { ref, reactive, computed, inject, onBeforeMount } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    const navigateToContact = () => {
        router.push('/contact');
        window.scrollTo(0, 0); 
    }

    // Inject

    // Props

    // Variables

    // Methods

    // Vue CicleLife

</script>


<style lang="scss" scoped>

    @media screen and (max-width: 600px) {
        .wbtn {
            width: 90% !important; 
        }
    }

    .img-hov {
        transition: .5s ease;
        opacity:0.85;
    }
    .img-hov:hover{
        box-shadow:
        3px 3px #D29751,
        4px 4px #D29751,
        5px 5px #D29751,
        6px 6px #D29751,
        7px 7px #D29751,
        8px 8px #D29751;
        -webkit-transform: translateX(-4px);
        transform: translateX(-4px);
        transition: .5s ease;
        opacity:1;
        transform: scale(1.05);
    }
    .imghover:hover{
        transform: scale(1.15);
        box-shadow: 3px 3px #D29751;
    } 
    
    .text-hov:hover{
        transform: scale(1.04);
        transition: .5s ease;
        text-shadow: 7px 7px 7px #000000;
    }

    .buttonshine:hover {
        animation-name: shine;
        animation-duration: 350ms;
        transform: scale(1.1);
        box-shadow: 6px 6px 6px #000;

    }

    @keyframes shine {
    0% {
        background: linear-gradient(
        30deg, 
        hsl(260, 85%, 95%) 0%, 
        #D29751 25%
        );
    }

    25% {
        background: linear-gradient(
        30deg, 
        #D29751 0%, 
        hsl(260, 85%, 95%) 25%, 
        #D29751 50%);
    }

    50% {
        background: linear-gradient(
        30deg,
        #D29751 0%, 
        hsl(260, 85%, 95%) 50%, 
        #D29751 75%); 
    }

    75% {
        background: linear-gradient(
        30deg, 
        #D29751 0%, 
        hsl(260, 85%, 95%) 75%, 
        #D29751 100%); 
    }

    100% {
        background: linear-gradient(
        30deg, 
        #D29751 0%, 
        hsl(260, 85%, 95%) 100%); 
        }
    }           
</style>