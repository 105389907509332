<template>
    <ta-navbar></ta-navbar>
    <div class="container-fluid" style="background-color: #242424;">
        <div class="row d-flex justify-content-around align-items-center p-5">
         <div class="col-12 pt-5 d-flex justify-content-end">
            <ta-modal2>
            </ta-modal2>
            </div>
            <div class="col-12 col-lg-7 px-5">
                <section>
                    <div class="mb-3">    
                        <span class="title-sm text-white">
                            Carrito de compras
                        </span>
                    </div>
                    <div class="mb-3">
                        <img :src="mncart" class="img-hov" style="width:100%;">
                    </div>
                </section>
            </div>
            <div class="col-12 col-lg-5 my-5 py-3" style="border-radius: 25px; background-color: #141414;" >
                <div class="row justify-content-between">
                    <div class="col-12">
                        <div class="my-3 text-start">
                            <span class="montserrat-bold description-md text-white ">
                                Mi Riqueza Fácil
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-6">
                        <div class="my-4 text-start">
                            <span class="montserrat-light description-sm text-white ">
                                Descripción
                                <br>    
                                Tipo de Venta
                                <br>      
                                Precio
                                <br>      
                                Comisión Bancaria
                                <br>      
                                Total
                            </span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="my-4 text-start">
                            <div class="my-3 text-start">
                            <span class="montserrat-light description-sm text-white ">
                                ...
                                <br>    
                                Curso en Línea
                                <br>    
                                $299.00 USD
                                <br>      
                                Precio
                                <br>      
                                $18.00 USD
                                <br>      
                                $317.00 USD
                            </span>
                        </div>
                        <div class="my-5">
                            <ta-modal>
                            </ta-modal>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ta-footer></ta-footer>
</template>
<script setup>
    import { ref } from 'vue';
    const showModal = ref(false);

    const mncart = require("ASSETS/images/cart/mncart")

    const icon_padlock = require("ASSETS/icons/padlock")

</script>
<style lang="scss" scoped>
    .navicon:hover{
        transform: scale(1.3);
        background-color: black;
        cursor: pointer;
    } 
    
</style>