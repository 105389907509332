<template>
    <ta-navbar></ta-navbar>
    <div class="bg-dark" style="background-repeat: space; background-size: cover;">

        <!--////////////////////////////////////////////////////////////////////////////////////////////-->
    <div class="row align-items-start mx-3">
        <div class="col-12 flex-center align-self-center p-lg-5 my-5">
            <span class="description-xs text-white text-start mx-md-3 my-5 text-uppercase" style="font-weight: 1000; text-shadow: 7px 7px 7px #000000; line-height: 1.8; font-size: 10pt" >
            <b class="description-sm">Acuerdo de Términos</b>
            <br>
            Estos Términos y condiciones de servicio y uso constituyen un acuerdo legalmente vinculante hecho entre usted, ya sea personalmente o en nombre de una entidad ("usted") y Aprende a Invertir LLC, que opera como Tony Almazan ("Tony Almazan", " nosotros ”,“ nos ”o“ nuestro ”), 
            en relación con su acceso y uso del sitio web www.tonyalmazan.com, así como cualquier otro formulario de medios, canal de medios, sitio web móvil o aplicación móvil relacionado, vinculado, o conectado de otro modo a él (colectivamente el "Sitio"). Usted acepta que al acceder al Sitio, 
            ha leído, comprendido y aceptado estar sujeto a todos estos Términos de uso. SI NO ESTÁ DE ACUERDO CON TODOS ESTOS TÉRMINOS DE USO, ENTONCES ESTÁ EXPRESAMENTE PROHIBIDO USAR EL SITIO Y DEBE DEJAR DE USARLO INMEDIATAMENTE. USTED ENTIENDE, RECONOCE Y ACEPTA QUE HACER CLIC EN "ACEPTO" 
            ES EL EQUIVALENTE LEGAL DE FIRMAR MANUALMENTE ESTOS TÉRMINOS Y CONDICIONES, Y QUE ESTARÁ LEGALMENTE OBLIGADO POR ESTOS TÉRMINOS Y CONDICIONES. El sitio www.tonyalmazan.com y todo el contenido y materiales relacionados y servicios (colectivamente, el "Sitio") es un recurso en línea 
            relacionado con la negociación de valores que proporciona a sus clientes y miembros salas virtuales interactivas, herramientas de negociación, inversión e investigación, y clases, materiales y recursos educativos para su uso por clientes y miembros en relación con sus actividades comerciales.
             "Valores", tal como se utilizan en este documento y para los fines de estos Términos y condiciones, incluirán, entre otros, dinero, valores, productos básicos y otras inversiones de todo tipo y naturaleza y todos los contratos y opciones relacionados con los mismos, ya sea para la entrega presente o futura. 
             En consideración al acceso y uso de cada miembro o cliente (cada uno, un "cliente" o "usted") del Sitio, requerimos que todos los miembros y clientes actúen con integridad, de acuerdo con nuestras reglas para el Sitio y que cumplan con estos Términos y condiciones y cada otra regla, regulación u otra política de Aprende a Invertir LLC. 
             Acepta leer estos Términos y condiciones detenidamente y conservarlos para referencia futura. Usted comprende, reconoce y acepta que estos Términos y condiciones rigen todos los aspectos de mi uso del Sitio y los productos y servicios de Aprende a Invertir LLC. Al hacer clic en "ACEPTO" y/o al usar o acceder al Sitio, usted reconoce que ha recibido, leído y comprendido estos Términos y Condiciones de Aprende a Invertir LLC.
            <br>
            <br>
            <b class="description-sm">Términos Generales</b>
            <br>

            Al hacer clic en "ACEPTO" y/o usar o acceder al Sitio, usted acepta, (a) que ha recibido, leído y comprendido estos Términos y Condiciones, y que estos Términos y Condiciones crean un acuerdo válido y vinculante, que es ejecutable contra usted de acuerdo con los términos del presente,
             (b) estar sujeto a estos Términos y condiciones, cualquier término, condición u otras reglas, regulaciones o políticas de Aprende a Invertir LLC, ya que cada uno puede ser enmendado o complementado de vez en cuando en nuestra única discreción sin previo aviso, y (c) que su uso del Sitio cumplirá con todas las leyes, 
             reglas o regulaciones federales, estatales y locales aplicables, y que usted es el único responsable de su cumplimiento, familiaridad y comprensión de dichas leyes, reglas o regulaciones aplicables a su uso del Sitio. Si no está de acuerdo con alguna parte de estos Términos y condiciones, tiene prohibido usar o acceder al Sitio. 
             Además, y sin limitar lo anterior, al usar o acceder al Sitio, usted nos declara y nos garantiza que: (a) tiene dieciocho (18) años de edad o más, (b) actualmente no tiene restricciones para usar el Sitio, o no tiene prohibido tener una cuenta con nosotros, (c) no es un competidor de Aprende a Invertir LLC, ni participa en ningún negocio o actividad, 
             directa o indirectamente, que pueda ser competitivo con el negocio o actividades de Aprende a Invertir LLC, y no está utilizando el Sitio por ningún motivo que pueda estar en competencia con el Sitio o cualquier otro producto o servicio ofrecido por Aprende a Invertir LLC, (d) tiene pleno poder y autoridad para aceptar y cumplir estos Términos y Condiciones, 
             y al hacerlo no violará ningún otro acuerdo en el que sea parte, (e) no violará ningún derecho de Aprende a Invertir LLC, incluidos, entre otros, los derechos de propiedad intelectual como patentes, derechos de autor o derechos de marca registrada, y (f) acuerda proporcionar, operar y mantener, a su solo riesgo, costo y gasto, todo el equipo, software y acceso 
             a Internet necesarios para usar el Sitio. Sin limitar la generalidad de lo anterior o cualquier otra disposición del mismo, usted reconoce y acepta lo siguiente:
            <br>
            Que el contenido, los materiales, los servicios y otra propiedad intelectual contenida o incorporada en el Sitio son propiedad de Aprende a Invertir LLC, y están protegidos por patentes, derechos de autor, marcas comerciales y otras leyes similares;
            <br>
            No promover, ofrecer para la venta o vender ningún producto o servicio, incluidos, entre otros, cualquier producto financiero o de inversión, seguridad o servicio, o cualquier concurso o promoción;
            <br>
            No transmitir, enviar o publicar comunicaciones comerciales no autorizadas (como correo no deseado) u otros materiales o anuncios similares utilizando el Sitio;

            No acceder al Sitio utilizando medios automatizados, incluidos, entre otros,terminosTérminos la recolección de bots, robots, “spiders” o raspadores;

            No participar en marketing multinivel utilizando el Sitio, incluidos, entre otros, esquemas piramidales y conceptos de marketing similares;

            No cargar, usar o difundir virus u otro código malicioso u otros scripts o procesos abusivos;

            No solicitar inicio de sesión, cuenta u otra información personal de otra persona ni solicitar u obtener acceso a una cuenta de otra persona;

            No acosar, intimidar o acosar a ninguna persona;
            <br>
            No publicar contenido que sea odioso, amenazante o pornográfico, que incite a la violencia o contenga desnudez, material gráfico, violencia gratuita o cualquier otra cosa que Aprende a Invertir LLC considere objetable a su exclusivo criterio;
            <br>
            No utilizar el Sitio de ninguna manera que sea, o pueda interpretarse razonablemente que es, ilegal, lo que incluye, entre otros, la violación de cualquier ley, regla o regulación relacionada con valores o inversiones, o cualquier regla o regulación promulgada por los EE. UU. Comisión de Bolsa y Valores, y/o reglas de cualquier bolsa de valores nacional o de otro tipo y cualquier reglamento u otro pronunciamiento que tenga fuerza de ley;
            <br>
            No utilizar el Sitio de ninguna manera que sea, o pueda interpretarse razonablemente que infringe estos Términos y Condiciones, fraudulenta, engañosa, maliciosa o discriminatoria;
            <br>
            No realizar ninguna acción que pueda deshabilitar, sobrecargar o perjudicar el funcionamiento o la disponibilidad del Sitio, como un ataque de denegación de servicio;
            <br>
            No participar en prácticas manipuladoras diseñadas para ofuscar la verdadera intención de sus envíos al Sitio, o para generar tráfico artificialmente a otro sitio web;
            <br>
            No facilitar ni fomentar ninguna infracción de estos Términos y condiciones;
            <br>
            No publicar ni realizar declaraciones, afirmaciones o alegaciones difamatorias, despectivas o falsas relacionadas con el Sitio, Aprende a Invertir LLC o cualquier otro producto o servicio de Aprende a Invertir LLC;
            <br>
            No hacer que su cuenta o cualquier parte de la misma o los servicios proporcionados por ella estén disponibles para el público en general o cualquier parte de los mismos;
            <br>
            No emitir disputas de devolución de cargo (chargebacks) contra Aprende a Invertir LLC.
            No utilizar propiedad intelectual patentada, con derechos de autor, marca registrada u otra propiedad intelectual protegida sin el consentimiento y la autorización por escrito del propietario de dicha propiedad;
            <br>
            No copiar, distribuir o difundir el Sitio o cualquier parte del mismo, y no transferir el Sitio, o cualquier parte del mismo, a otra persona o “duplicar” el Sitio, o cualquier parte del mismo, en cualquier otro servidor;
            <br>
            No descompilar o realizar ingeniería inversa, o intentar descompilar el Sitio o cualquier parte del mismo;
            <br>
            y
            No tomar ninguna otra acción que pueda resultar en cualquier daño o interrupción del Sitio, o que de otra manera podría resultar en responsabilidad, daños, costos o gastos por parte de Aprende a Invertir LLC.
            
            No compartir información personal con miembros ni solicitar información personal a miembros.
            
            Todo el contenido, los materiales y los servicios relacionados con el Sitio se utilizarán únicamente, y usted acepta utilizarlo únicamente para su uso personal en relación con sus actividades de inversión y para ningún otro uso.
            
            La violación de cualquiera de los anteriores resultará en la eliminación inmediata de Aprende a Invertir LLC.
            <br>
            <br>
            <b class="description-sm">Cookies</b>
            <br>
            Si deja un comentario en nuestro sitio, puede optar por guardar su nombre, dirección de correo electrónico y sitio web en cookies. Estos son para su conveniencia para que no tenga que volver a completar sus datos cuando deje otro comentario. 
            Estas cookies tendrán una duración de un año. Si visita nuestra página de inicio de sesión, configuraremos una cookie temporal para determinar si su navegador acepta cookies. Esta cookie no contiene datos personales y se descarta cuando cierra su navegador. 
            Cuando inicie sesión, también configuraremos varias cookies para guardar su información de inicio de sesión y sus opciones de visualización de pantalla. Las cookies de inicio de sesión duran dos días y las cookies de opciones de pantalla duran un año. Si selecciona "Recordarme", 
            su inicio de sesión persistirá durante dos semanas. Si cierra la sesión de su cuenta, las cookies de inicio de sesión se eliminarán. Si edita o publica un artículo, se guardará una cookie adicional en su navegador. Esta cookie no incluye datos personales y simplemente indica el ID de publicación del artículo que acaba de editar. Caduca después de 1 día.
            Propiedad;
            <br>
             Licencia limitada
             <br>
            Al convertirse en cliente de Aprende a Invertir LLC y utilizar el Sitio, Aprende a Invertir LLC le otorga una licencia limitada revocable para usar el Sitio, y su contenido, materiales y servicios, únicamente para su uso personal y para los fines establecidos en el Sitio, y sujeto a estos Términos y Condiciones y a todas las demás reglas, 
            regulaciones y políticas impuestas o adoptadas por Aprende a Invertir LLC en relación con el Sitio, que pueden ser modificadas por Aprende a Invertir LLC en cualquier momento a su entera discreción y sin previo aviso. Tal licencia revocable limitada es libremente revocable y terminable por Aprende a Invertir LLC en cualquier momento, sin causa y a la sola discreción de Aprende a Invertir LLC. 
            Sin limitar lo anterior, esta licencia revocable limitada se revocará y terminará automáticamente ante cualquier violación de estos Términos y condiciones o cualquier otra regla, regulación o política de Aprende a Invertir LLC. Tras la revocación o terminación de esta licencia revocable limitada, usted deberá, y por la presente acepta, destruir cualquier material (electrónico o de otro tipo) relacionado con el Sitio que permanezca en su posesión o control, 
            y reconocer que después de dicha revocación o terminación, Aprende a Invertir LLC puede negar su acceso al Sitio y cualquier otro contenido, materiales o servicios de Aprende a Invertir LLC a su entera discreción. Cualquier información personal de un usuario cuya licencia revocable limitada sea revocada o cancelada por Aprende a Invertir LLC se conservará o eliminará de acuerdo con los términos de la Política de privacidad de Aprende a Invertir LLC. 
            Cualquier información que envíe a Aprende a Invertir LLC está bajo su propio riesgo de pérdida, y Aprende a Invertir LLC no asume responsabilidad alguna por ello.

            Aprende a Invertir LLC se reserva todos los derechos no otorgados expresamente en estos Términos y condiciones, incluidos, entre otros, el título, la propiedad, los derechos de propiedad intelectual y todos los demás derechos, títulos e intereses en y para el Sitio, los productos y servicios de Aprende a Invertir LLC y todo el contenido relacionado, materiales, servicios y otros documentos, información o elementos, incluidas copias u obras derivadas de los mismos. 
            Usted reconoce y acepta que el Sitio, los productos y servicios de Aprende a Invertir LLC, y todo el contenido, materiales, servicios y otros documentos, información o elementos relacionados, incluidas las copias o trabajos derivados del mismo, son propiedad de Aprende a Invertir LLC y están protegidos por derechos de autor, marcas registradas y otras leyes de propiedad intelectual. Además, reconoce y acepta que el Sitio, los productos y servicios de Aprende a Invertir LLC y el contenido, materiales, 
            servicios y otros documentos, información o elementos relacionados, incluidas las copias o trabajos derivados del mismo, contienen ciertos datos e información de propiedad de Aprende a Invertir LLC, que usted no utilizará estos datos o información para ningún propósito ilegal o no autorizado, y que hará todos los esfuerzos razonables para protegerlos de la distribución o uso ilícitos.

            Además, otorga a Aprende a Invertir LLC un derecho exclusivo, irrevocable, mundial, perpetuo, ilimitado, libremente asignable y sublicenciable, totalmente pagado y libre de regalías para usar, copiar, preparar trabajos derivados de, mejorar, distribuir, publicar, eliminar, retener, agregar, procesar, analizar y comercializar, de cualquier manera ahora conocida o descubierta en el futuro, cualquier información, contenido, ideas, conceptos, técnicas, datos o sugerencias enviadas al Sitio con el propósito de mejorar o modificar el Sitio, 
            o cualquier contenido, materiales o servicios, o con el propósito de crear o desarrollar nuevos contenidos, materiales, servicios o productos relacionados con, derivados de, auxiliares o similares al Sitio, sin ningún otro consentimiento, notificación y/o compensación para usted o para cualquier terceros.

            Consentimiento para utilizar audio, video y fotografía de eventos en vivo
            Aprende a Invertir LLC puede organizar eventos en persona de vez en cuando. Estos eventos pueden grabarse y fotografiarse para fines de marketing y/o producción de contenido educativo. Al asistir a los eventos de Aprende a Invertir LLC, usted acepta permitir que Aprende a Invertir LLC utilice audio, video y fotografías en las que pueda aparecer sin ningún otro consentimiento, notificación y/o compensación para usted o para cualquier terceros.
            Violación;
            <br>
             Terminación de cuenta
            Aprende a Invertir LLC se reserva el derecho de investigar violaciones de cualquiera de estos Términos y Condiciones o de cualquier otra política de Aprende a Invertir LLC o del Sitio, o cualquier otra violación de cualquier ley, regla o regulación estatal, federal o local, y buscar cualquier solución. disponible para Aprende a Invertir LLC ya sea en derecho, en equidad o de otro modo. Por la presente, reconoce y acepta que Aprende a Invertir LLC puede notificar, 
            involucrar y cooperar con las autoridades encargadas de hacer cumplir la ley en la investigación y enjuiciamiento de los usuarios que violen estos Términos y condiciones o cualquier otra política de Aprende a Invertir LLC o el Sitio, y cualquier otra violación de cualquier estado, leyes, reglas o regulaciones federales o locales. Además, reconoce y acepta que Aprende a Invertir LLC no tiene la obligación de, y no examina ni supervisa a ningún usuario, información, 
            material u otro contenido proporcionado o puesto a disposición a través del Sitio, pero tiene derecho a hacerlo en cualquier momento en su única discreción.

            Además, reconoce y acepta que Aprende a Invertir LLC puede, a su sola discreción, eliminar o eliminar cualquier entrada, información, material u otro contenido, y cancelar la suscripción, membresía o cuenta (colectivamente, su "cuenta") de cualquier usuario, que viole estos Términos y Condiciones o publique, envíe o difunda contenido que Aprende a Invertir LLC considere, a su exclusivo criterio, objetable.

            Usted reconoce y acepta que Aprende a Invertir LLC puede, a su exclusivo criterio, cancelar, suspender o cancelar su cuenta si incumple estos Términos y Condiciones. Aprende a Invertir LLC puede negarse, a su entera discreción, a permitir cualquier uso del Sitio, y puede negarse a permitir que cualquier persona acceda o utilice el Sitio o su cuenta por cualquier motivo y en cualquier momento.
            <br>
            <br>    
            <b class="description-sm">Compras y reembolsos</b><br> 
            Aprende a Invertir LLC ofrece productos individuales para la venta y suscripción, membresía u otros servicios periódicos basados en tarifas. Para cualquier compra a través del Sitio, se requerirá una tarjeta de crédito válida para completar la compra y el monto de la compra se cargará a la tarjeta de crédito.

            Al comprar una suscripción, membresía u otros servicios periódicos basados en tarifas en el Sitio, se requerirá una tarjeta de crédito válida para fines de facturación. Por la presente, usted acepta, reconoce y permite que Aprende a Invertir LLC almacene (o haga que un tercero almacene en nombre de Aprende a Invertir LLC) dicha información de pago y acepta las siguientes políticas y procedimientos para el pago de suscripción, membresía u otras tarifas periódicas relacionadas al sitio.

            Inmediatamente después de registrarse para obtener una cuenta u otros servicios periódicos basados ​​en tarifas en el Sitio, las tarifas asociadas con el primer período o término de su cuenta o servicio seleccionado (mensual, trimestral, anual, según corresponda) se cargarán a dicha tarjeta de crédito dentro de los diez (10) días hábiles anteriores al vencimiento del período o término inicialmente seleccionado, y cada período o término subsiguiente hasta que su cuenta u otro servicio periódico se cancele de acuerdo con estos Términos y condiciones, 
            las tarifas asociadas con su cuenta seleccionada o servicio para el siguiente período o término aplicable se renovará automáticamente, y Aprende a Invertir LLC facturará y cobrará un monto por su cuenta o servicio renovado a dicha tarjeta de crédito de acuerdo con el programa de precios vigente en ese momento. Si la tarjeta de crédito asociada con su cuenta o servicio expira, termina o cualquier pago es rechazado por la compañía emisora, Aprende a Invertir LLC puede cancelar inmediatamente su cuenta o servicio. 
            Es su responsabilidad garantizar (1) que la información válida de la tarjeta de crédito permanezca archivada para su cuenta o servicio y la renovación automática de la misma, y ​​(2) que una dirección de correo electrónico válida permanezca archivada para su cuenta o servicio para cualquier comunicación de Aprende a Invertir LLC relacionado con el mismo.

            Aprende a Invertir LLC no ofrece ni está obligado a proporcionar ningún reembolso o crédito por ningún motivo, incluidos, entre otros, la satisfacción o el hecho de que no cancele su cuenta o servicio antes de su renovación automática. No existe ninguna circunstancia en la que tenga derecho a, o Aprende a Invertir LLC esté obligado a proporcionar, un reembolso o crédito por cualquier motivo.

            Todas las tarifas cobradas por Aprende a Invertir LLC en relación con cualquier producto comprado o cuenta o servicio del Sitio son exclusivas de cualquier impuesto, gravamen o tasa impuesta por cualquier autoridad fiscal, y usted será, y por la presente es, responsable del pago de todos los impuestos, gravámenes o aranceles que surjan de la compra de productos o del uso del Sitio.
            <br>
            <br>    
            <b class="description-sm">Modificaciones al sitio y precios</b><br>
            Aprende a Invertir LLC se reserva el derecho, y usted reconoce y acepta que Aprende a Invertir LLC puede modificar el Sitio, incluidos, entre otros, el contenido, los materiales y los servicios ofrecidos por el mismo, y las tarifas, costos y precios asociados con el Sitio en cualquier momento, tiempo y sin previo aviso. Sin limitar la generalidad de lo anterior, Aprende a Invertir LLC se reserva el derecho, en cualquier momento y de vez en cuando, de modificar o interrumpir, temporal o permanentemente, el Sitio (o cualquier parte o parte del mismo) sin previo aviso. 
            Cualquier característica nueva que aumente o mejore la versión actual del Sitio, incluido el lanzamiento de nuevas herramientas y recursos, estará sujeta a estos Términos y Condiciones y puede resultar, a la entera discreción de Aprende a Invertir LLC, en un aumento de precio. Aprende a Invertir LLC no será responsable ante usted ni ante ningún tercero por daños, costos, gastos u otras responsabilidades relacionadas con cualquier modificación, cambio de precio, suspensión o interrupción del Sitio. Sin limitar lo anterior, 
            Aprende a Invertir LLC puede usar avisos publicitarios o dispositivos similares para alertarlo sobre ciertas modificaciones al Sitio o los precios asociados con el mismo. Alternativamente, el aviso puede consistir en un correo electrónico del Sitio a una dirección de correo electrónico asociada con su cuenta o servicio, incluso si tenemos otra información de contacto. También acepta que Aprende a Invertir LLC puede comunicarse con usted a través de cualquier medio disponible, incluidos correo electrónico, número de teléfono móvil, teléfono o servicios de entrega, 
            incluido el servicio postal, sobre su cuenta o servicio o cualquier producto o servicio asociado con el Sitio. Usted reconoce y acepta que no tendremos ninguna responsabilidad asociada con o que surja de su falta de mantener un contacto preciso u otra información, incluyendo, pero no limitado a, su falta de recibir información crítica sobre el Sitio o los precios.
            Cancelaciones
            Su cuenta o servicio puede cancelarse, sujeto a los términos del presente, en cualquier momento
            <br>
            siempre que, como se establece anteriormente, no se emitirán reembolsos si su cuenta u otro servicio periódico basado en tarifas se cancela antes del final del período o término aplicable. Cuando cancela, usted es el único responsable de cancelar correctamente su cuenta o servicio. Las solicitudes de cancelación por teléfono, fax u otros medios de comunicación no pueden, ni serán, aceptadas, respetadas o efectivas. La cancelación de su cuenta o servicio resultará inmediatamente en la desactivación y eliminación de su cuenta o servicio, la denegación de acceso al Sitio y 
            la pérdida y renuncia de todo el contenido y la información dentro o relacionados con su cuenta o servicio. No se puede recuperar ningún dato, contenido o información una vez que se cancela su cuenta o servicio, y usted asume toda la responsabilidad de preservar cualquier dato, contenido o información en su cuenta o servicio antes de que Aprende a Invertir LLC pueda retener datos, contenido o información de su cuenta después de la cancelación en copias de seguridad y/o copias de archivo del Sitio y bases de datos relacionadas, pero dichas copias, si las hubiera, no estarán disponibles para usted.
            Descargo de responsabilidad general de garantías
            Aprende a Invertir LLC RENUNCIA A CUALQUIER GARANTÍA POR CUALQUIER INFORMACIÓN, CONTENIDO O CONSEJO OBTENIDO A TRAVÉS DEL SITIO;
            <br>
             Y, APRENDE A INVERTIR RENUNCIA A CUALQUIER GARANTÍA DE SERVICIOS O BIENES RECIBIDOS O PUBLICITADOS EN EL SITIO O RECIBIDOS A TRAVÉS DE LOS ENLACES PROPORCIONADOS POR EL SITIO. USTED ENTIENDE, RECONOCE Y ACEPTA QUE SU USO DEL SITIO, Y CUALQUIER CONTENIDO, SERVICIOS U OTRA INFORMACIÓN O MATERIALES RELACIONADOS CON ELLO, ES EXCLUSIVAMENTE A SU PROPIA DISCRECIÓN Y RIESGO Y QUE USTED SERÁ EL ÚNICO RESPONSABLE DE CUALQUIER RESPONSABILIDAD, RECLAMACIÓN, DAÑOS, PÉRDIDAS , COSTOS O GASTOS, QUE RESULTEN O SURGEN DE SU USO DEL SITIO, O CUALQUIER CONTENIDO, SERVICIOS U OTRA INFORMACIÓN O MATERIALES RELACIONADOS CON ELLO. 
             POR LA PRESENTE, USTED RENUNCIA EXPRESAMENTE Y LIBERA Y RENUNCIA A CUALQUIER RESPONSABILIDAD, RECLAMO, DAÑOS, PÉRDIDAS, COSTOS O GASTOS, RELACIONADOS O DERIVADOS DE, LAS GARANTÍAS DECLARADAS POR Aprende a Invertir LLC
            Tu responsabilidad
            Aprende a Invertir LLC no está registrado como asesor de inversiones en la U.S. Securities and Exchange Commission. Más bien, Aprende a Invertir LLC se basa en la "exclusión del editor" de la definición de "asesor de inversiones" según lo dispuesto en la Sección 202 (a) (11) (D) de la Ley de Asesores de Inversiones de 1940 y las leyes estatales correspondientes. Como tal, Aprende a Invertir LLC no ofrece ni proporciona asesoramiento de inversión personalizado. En la medida en que cualquier contenido, material, información y/o cualquier otro tipo de oferta informativa publicada, 
            difundida o que se indique de otra manera en este y/o sitios web asociados, pueda considerarse un "consejo de inversión", dicha información es impersonal y no se adapta a las necesidades de inversión de ninguna persona específica. La información contenida en este y/o sitios web asociados se proporciona únicamente con fines informativos y educativos. La información no debe interpretarse como asesoramiento de inversión o trading y no pretende ser una solicitud o recomendación para comprar, vender o mantener posiciones en los índices o mercados financieros mencionados. 
            El contenido y la información proporcionada por Aprende a Invertir LLC, el Sitio y los demás productos y servicios de Aprende a Invertir LLC, es únicamente incidental al negocio y las actividades de Aprende a Invertir LLC en la prestación de servicios educativos.
            Usted sigue siendo el único responsable de todas las decisiones relacionadas con su compra y venta de valores, la idoneidad o rentabilidad para usted de cualquier valor, inversión, producto financiero, estrategia de inversión u otro asunto, y todos los demás asuntos relacionados con sus inversiones y estrategias de inversión. Aprende a Invertir LLC no le proporciona ni le proporcionará ningún asesoramiento legal, fiscal, de planificación patrimonial o contable, ni ningún consejo sobre la idoneidad o rentabilidad para usted de ningún valor, inversión, 
            producto financiero, estrategia de inversión u otro asunto. Usted comprende, reconoce y acepta que los empleados de Aprende a Invertir LLC no están autorizados a dar tales consejos, no solicitará ni dependerá de ningún consejo de inversión de ningún empleado de Aprende a Invertir LLC. Aprende a Invertir LLC recomienda que las personas que deseen negociar o invertir en valores lo hagan con cautela y sólo en consulta con sus asesores financieros, legales, fiscales, de planificación patrimonial y contables profesionales, autorizados y calificados.
            Ningún dato, contenido o información proporcionada por Aprende a Invertir LLC, el Sitio u otros productos y servicios de Aprende a Invertir LLC , está destinado, y no constituirá ni será interpretado como un consejo o recomendación para comprar o vender valores, ni una oferta, o solicitud de una oferta para comprar o vender valores, ni un intento de influir en la compra o venta de ningún valor. Aprende a Invertir LLC no está registrado como asesor de inversiones ante la U.S. Securities and Exchange Commission, ninguna otra autoridad gubernamental o 
            reguladora federal o nacional, ni ninguna autoridad gubernamental o reguladora de valores estatales, y los negocios y actividades de Aprende a Invertir LLC no requieren cualquier registro de este tipo.
            <br>
            <br>
            <b class="description-sm">Actividades del personal Aprende a Invertir LLC</b><br>    
            Aprende a Invertir LLC y sus directores, funcionarios, accionistas, empleados, agentes y afiliados son inversores activos y comerciantes de valores. Dichos participantes pueden, pero no están obligados a comprar o vender ningún valor, ni participar en ninguna estrategia de inversión, contenida en cualquier dato, contenido o información en el Sitio, o en los otros productos o servicios de Aprende a Invertir LLC . Dichas participantes comprarán o venderán valores y participarán en cualquier estrategia de inversión, a su entera discreción, en cualquier momento y sin previo aviso, y no deberán, y no están obligados a revelar o notificarle a usted, o a cualquier otra persona o participante, con respecto a tales decisiones o actividades. 
            Para evitar dudas, es posible que dichos participantes no siempre se adhieran a las estrategias de Aprende a Invertir LLC y no estén obligadas a revelar o notificarle a usted, ni a ninguna otra persona o participante, con respecto a tales decisiones o actividades. El personal de Aprende a Invertir LLC se adhiere a sus planes individuales únicos desarrollados e implementados por ellos con o sin Aprende a Invertir LLC , y las experiencias no se duplicarán y no son típicas.
            Aprende a Invertir LLC no participa, y sus negocios y actividades no constituirán ni se interpretarán como negocios o actividades con el propósito de crear una apariencia falsa o engañosa de negociación activa de cualquier valor, o una apariencia falsa o engañosa con respecto al mercado por dicho valor, para efectuar cualquier transacción en dicho valor que no implique un cambio en el beneficiario final del mismo, para ingresar una orden u órdenes para la compra de dicha garantía con el conocimiento de que la orden u órdenes de sustancialmente el mismo tamaño, en sustancialmente al mismo tiempo, y sustancialmente al mismo precio, para la venta de dicho valor, ha sido o será ingresado por o para las mismas o diferentes partes o partidos.
            Aprende a Invertir LLC no participa, y su negocio y actividades no constituirán ni se interpretarán como un negocio o actividad para llevar a cabo una serie de transacciones que creen una negociación activa real o aparente en un valor, o que aumente o deprima el precio de dicho valor con el propósito de inducir la compra o venta de dicho valor por parte de terceros.
            Aprende a Invertir LLC no participa, y su negocio y actividades no constituirán ni se interpretarán como, un negocio o actividad para efectuar una serie de transacciones para la compra y/o venta de un valor con el propósito de vincular, fijar o estabilizar el precio de dicha garantía en contravención de cualquier ley, norma o reglamento.
            Usted comprende, reconoce y acepta que Aprende a Invertir LLC y sus directores, funcionarios, accionistas, empleados, agentes y afiliados participan en la negociación activa de valores, pero no participan voluntariamente en ninguna actividad descrita en los tres párrafos anteriores. En consecuencia, por la presente, renuncia y libera todas y cada una de las reclamaciones, pérdidas, daños, responsabilidades y cualquier otro costo y gasto (incluidos los honorarios de los abogados) que surjan de o estén relacionados con su compra o venta de un valor a un precio que pueda haber sido afectado por el negocio o las actividades de Aprende a Invertir LLC , o sus directores, funcionarios, accionistas, empleados, agentes o afiliados.

            <br>
            <br>
            <b class="description-sm">Inversiones y Resultados</b><br>
            La compra y venta de valores, futuros o materias primas implica un alto grado de riesgo y es de naturaleza especulativa. La compra y venta de valores solo debe ser realizada por personas que comprendan y acepten los riesgos que conllevan y que hayan revisado y determinado de forma independiente su aceptación e idoneidad de estos riesgos y sus consecuencias financieras y fiscales. Solo las personas que puedan asumir el riesgo de una pérdida sustancial o total de fondos, deben participar en la compra y venta de valores. Aprende a Invertir LLC , y el contenido y la información contenida en el Sitio, y en otros productos y servicios de Aprende a Invertir LLC, no indican ni garantizan ningún resultado predecible, general, específico o de otro tipo. 
            La compra y venta de valores, y cualquier otra actividad de inversión, implica un alto grado de riesgo, y una serie de factores podrían afectar de manera material y adversa los resultados y conducir a una pérdida sustancial o total de la inversión. Como se establece más completamente en este documento, Aprende a Invertir LLC, y sus directores, funcionarios, accionistas, empleados, agentes y afiliados, no hacen ninguna representación o garantía en cuanto a la validez, adecuación, puntualidad, precisión, confiabilidad o integridad de los datos, contenido o información. Debe analizar, revisar y confirmar de forma independiente dichos datos, contenido o información. 
            Aprende a Invertir LLC puede expresar o utilizar testimonios o descripciones de desempeño pasado, pero dichos elementos no son indicativos de resultados o desempeño futuros, ni ninguna representación o garantía de que usted obtendrá algún resultado. Sus resultados pueden diferir materialmente de los expresados o utilizados por Aprende a Invertir LLC debido a una serie de factores. Los endosos, testimonios o descripciones del desempeño pasado de otros clientes o miembros se basan en sus experiencias y resultados individuales con el Sitio y los productos y servicios de Aprende a Invertir LLC. Estos resultados y rendimientos no son típicos, y no debe esperar obtener resultados o rendimiento iguales o similares, y es probable que sus resultados y rendimiento varíen o difieran materialmente. 
            Los respaldos, testimonios o descripciones de desempeño pasado son experiencias individuales, que reflejan experiencias de la vida real de clientes o miembros, y no son representativos de los resultados y desempeños de todos los clientes y miembros. Los endosos, testimonios o descripciones del desempeño pasado que aparecen en el Sitio fueron recibidos a través de mensajes de texto, audio o video de clientes o miembros. Los endosos, testimonios o descripciones de actuaciones pasadas (texto, audio y/o video) son textuales, excepto para la corrección de errores gramaticales o mecanográficos y la edición de la extensión. En otras palabras, puede que no se muestre todo el mensaje recibido por el cliente o miembro, cuando parece extenso o cuando no todo el contenido parece relevante para el público en general. 
            Ningún endoso, testimonios o descripciones de clientes o miembros que aparezcan en el Sitio involucraron pago o compensación por los mismos.
            Sitios web de terceros<br>
            El Sitio y otros productos y servicios de Aprende a Invertir LLC pueden contener enlaces a otros sitios web o aplicaciones. El hecho de que tengamos un enlace a un sitio web no es un respaldo, autorización o representación de nuestra afiliación con ese tercero. Aprende a Invertir LLC no ha revisado todos los sitios vinculados hacia o desde el Sitio, u otros productos y servicios de Aprende a Invertir LLC, y no es responsable del contenido de dichos sitios web vinculados. No ejercemos control sobre los sitios web de terceros. El uso de enlaces a otros sitios web es bajo su propio riesgo. No somos responsables de los sitios de terceros vinculados desde el Sitio, u otros productos y servicios de 
            Aprende a Invertir LLC ni hacemos ninguna representación o garantía, expresa o implícita, con respecto a dicho sitio web, cualquier contenido de dichos sitios web o cualquier productos o servicios ofrecidos por dichos sitios web, incluidos, entre otros, precisión, integridad, confiabilidad, idoneidad, no infracción o comerciabilidad para un propósito particular.
            Indemnización<br>
            Usted deberá, y por la presente acepta, indemnizar y eximir de responsabilidad a Aprende a Invertir LLC y sus funcionarios, directores, accionistas, empleados, agentes y afiliados, de todas y cada una de las reclamaciones, pérdidas, daños, responsabilidades y cualquier otro costo y gasto (incluido honorarios de abogados), que surjan de o estén relacionados con su (i) incumplimiento de una representación o garantía contenida en estos Términos y condiciones, (ii) incumplimiento de cualquier término contenido en estos Términos y condiciones, (iii ) uso del Sitio o cualquier producto o servicio de Aprende a Invertir LLC, o (iv) uso, acceso u otra actividad involucrada en, a través, relacionada con o en conexión con el Sitio.
            Privacidad e información personal
            Aprende a Invertir LLC tendrá derecho a usar y divulgar cualquier información proporcionada por usted a través del Sitio u otros productos o servicios de Aprende a Invertir LLC (incluida, entre otros, la información proporcionada a través del correo electrónico) o disponible a través de su uso del Sitio, para cualquier propósito legal a menos que usted indique lo contrario de acuerdo con las políticas y procedimientos de Aprende a Invertir LLC. En ningún caso tendrá derecho a compensación alguna por el uso de dicha información. Consulte la Política de privacidad o más detalles.
            Modificaciones<br>
            Aprende a Invertir LLC se reserva el derecho de revisar estos Términos y Condiciones en cualquier momento sin previo aviso. Al usar el Sitio, usted acepta estar sujeto a la versión vigente en ese momento de estos Términos y condiciones, y su uso continuo del Sitio reafirma su aceptación de los Términos y condiciones vigentes en ese momento. Acepta consultar el Sitio con regularidad para obtener información actualizada sobre estos Términos y condiciones y la Política de privacidad. Aprende a Invertir LLC puede realizar cambios en el Sitio y los demás productos y servicios de Aprende a Invertir LLC en cualquier momento sin previo aviso. Aprende a Invertir LLC, sin embargo, no se compromete a actualizar el Sitio y los demás productos y servicios de Aprende a Invertir LLC.
            Ley que rige
            Cualquier reclamo en virtud de estos Términos y condiciones o relacionado con el Sitio o Aprende a Invertir LLC se regirá por las leyes del estado de California sin tener en cuenta sus disposiciones sobre conflicto de leyes, y será resuelto exclusivamente por un tribunal estatal o federal ubicado en California. Usted acepta someterse a la jurisdicción personal de los tribunales ubicados dentro de California, u otra ubicación que elija Aprende a Invertir LLC, con el fin de litigar todas esas reclamaciones. Sin perjuicio de lo anterior, usted acepta que Aprende a Invertir LLC aún podrá solicitar recursos por mandato judicial (o un tipo equivalente de desagravio legal urgente) en cualquier jurisdicción. Salvo que se disponga lo contrario en el presente, usted acepta que cualquier controversia o reclamo, 
            ya sea de derecho o equidad, que surja de o esté relacionado con la prestación de servicios o materiales por parte de Aprende a Invertir LLC, independientemente de la fecha de acumulación de dicho conflicto, se resolverá en su totalidad mediante arbitraje vinculante individual (no colectivo ni de clase). El Sitio y los demás productos y servicios de Aprende a Invertir LLC están dirigidos y destinados exclusivamente a personas que residen en los Estados Unidos. Aquellos que opten por acceder y utilizar el Sitio, y los demás productos y servicios de Aprende a Invertir LLC, desde otras ubicaciones, lo hacen bajo su propio riesgo y son responsables del cumplimiento de todas las leyes, reglas y regulaciones aplicables al mismo. Aprende a Invertir LLC se reserva el derecho de limitar la disponibilidad del Sitio, 
            y cualquier otro producto o servicio de Aprende a Invertir LLC, a cualquier persona, área geográfica o jurisdicción, de cualquier manera y en cualquier momento, a su exclusivo criterio.
            Acuerdo completo
            Usted acepta que estos Términos y Condiciones constituyen el acuerdo completo y exclusivo entre usted y nosotros con respecto al Sitio y los productos y servicios de Aprende a Invertir LLC, y reemplaza todos los acuerdos y entendimientos anteriores, ya sean escritos u orales, o establecidos por costumbre, práctica, política o precedente, con respecto al tema de estos Términos y Condiciones. Las disposiciones de estos Términos y Condiciones sobrevivirán a cualquier terminación de los mismos por tiempo indefinido.
            Exenciones
            El hecho de que no actuemos con respecto a un incumplimiento de estos Términos y condiciones por usted u otros no renuncia a nuestro derecho a actuar con respecto a ese incumplimiento o incumplimientos posteriores similares u otros.
            ¿Preguntas?
            Las preguntas sobre estos Términos y condiciones deben dirigirse a privacy@tonyalmazan.com Las preguntas sobre facturación deben dirigirse a privacy@tonyalmazan.com SI NO ESTÁ DE ACUERDO CON CUALQUIER TÉRMINO O DISPOSICIÓN DE ESTOS TÉRMINOS Y CONDICIONES, SALGA DEL SITIO INMEDIATAMENTE. TENGA EN CUENTA QUE SU USO CONTINUADO DE ESTE SITIO O LOS PRODUCTOS O LA INFORMACIÓN PROPORCIONADA POR EL MISMO INDICARÁN SU CONSENTIMIENTO Y ACUERDO CON ESTOS TÉRMINOS Y CONDICIONES. Estos sitios web pueden recopilar datos sobre usted, utilizar cookies, incrustar un seguimiento adicional de terceros y supervisar su interacción con ese contenido incrustado, incluido el seguimiento de su interacción con el contenido incrustado si tiene una cuenta y ha iniciado sesión en ese sitio web.
            </span>
        </div>
    </div>
        <!--////////////////////////////////////////////////////////////////////////////////////////////-->

        <!--////////////////////////////////////////////////////////////////////////////////////////////--> 

        <!--////////////////////////////////////////////////////////////////////////////////////////////--> 

        <!--////////////////////////////////////////////////////////////////////////////////////////////--> 
    </div>
    <ta-footer></ta-footer>
</template>

<script setup>
    // Images
    //require name_imagen ('')

        //navbar
    const icon = require("ASSETS/icons/search")    
    const icon1 = require("ASSETS/icons/facebook")
    const icon2 = require("ASSETS/icons/youtube")
    const icon3 = require("ASSETS/icons/instagram")
        //MnFrontSection
    const logomn = require("ASSETS/images/logo") 
    const mnpic = require("ASSETS/images/tonymn") 
    const mn2pic = require("ASSETS/images/mnimg") 
    const mnpic2 = require("ASSETS/images/mnsection2") 
        //bluelabel
    const iconBlue1 = require("ASSETS/icons/blue1")
    const iconBlue2 = require("ASSETS/icons/blue2")
    const iconBlue3 = require("ASSETS/icons/blue3")
    const iconBlue4 = require("ASSETS/icons/blue4")
        //sixpic
    const picsix1 = require("ASSETS/images/six1")
    const picsix2 = require("ASSETS/images/six2")
    const picsix3 = require("ASSETS/images/six3")
    const picsix4 = require("ASSETS/images/six4")
    const picsix5 = require("ASSETS/images/six5")
    const picsix6 = require("ASSETS/images/six6")
        //////
    const bgmn = require("ASSETS/images/mnbg") 
        /////
    const line3 = require("ASSETS/images/lineas3")
    const line2 = require("ASSETS/images/lineas2")    
    


    
    // Vue Imports
    import { Footer, Navbar } from '@/components';
    import { ref, reactive, computed, inject, onBeforeMount } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Inject

    // Props

    // Variables

    // Methods

    // Vue CicleLife

</script>


<style lang="scss" scoped>
    #home {
        
    }
    .imghover:hover{
        transform: scale(1.15);
        box-shadow: 3px 3px #D29751;
    } 

    .buttonshine:hover {
        animation-name: shine;
        animation-duration: 350ms;
        transform: scale(1.1);
        box-shadow: 6px 6px 6px #000;

    }

    @keyframes shine {
    0% {
        background: linear-gradient(
        30deg, 
        hsl(260, 85%, 95%) 0%, 
        #D29751 25%
        );
    }

    25% {
        background: linear-gradient(
        30deg, 
        #D29751 0%, 
        hsl(260, 85%, 95%) 25%, 
        #D29751 50%);
    }

    50% {
        background: linear-gradient(
        30deg,
        #D29751 0%, 
        hsl(260, 85%, 95%) 50%, 
        #D29751 75%); 
    }

    75% {
        background: linear-gradient(
        30deg, 
        #D29751 0%, 
        hsl(260, 85%, 95%) 75%, 
        #D29751 100%); 
    }

    100% {
        background: linear-gradient(
        30deg, 
        #D29751 0%, 
        hsl(260, 85%, 95%) 100%); 
        }
    }           
</style>