<template>
        <nav class="container-fluid position-fixed" style="height: 80px; background-color: #0c0c0c; border-bottom: 2px solid rgb(0, 0, 0); z-index: 5; box-shadow: 0px 20px 40px #0a0a0a;" >
            <div class="flex-between-center h-100 mx-xl-5">
                <div class="flex-center-center h-100 ms-md-5 ms-2" >
                    <a href="https://www.facebook.com/tonyalmazanofficial" target="_blank">
                        <div class="mx-md-3 mx-sm-2 mx-1 imghover">
                            <img :src="icon_fb" class="img-fluid" width="35" height="35" alt="facebook" loading="lazy">
                        </div>
                    </a>
                    <a href="https://www.youtube.com/user/inversiones123web" target="_blank" >
                        <div class="mx-md-3 mx-sm-2 mx-1 imghover">
                            <img :src="icon_yt" class="img-fluid" width="35" height="35" alt="youtube">
                        </div>
                    </a>
                    <a href="https://www.instagram.com/tonyalmazanofficial/" target="_blank">
                        <div class="mx-md-3 mx-sm-2 mx-1 imghover">
                            <img :src="icon_ig" class="img-fluid" width="35" height="35" alt="instagram">
                        </div>
                    </a>
                </div>
                    <div class="flex-center h-100 mx-xl-5">
                        <a class="text-center text-secondary ms-xl-5 me-3" @click="navigateToHome">
                            <img :src="tonygold" class="img-fluid m-3 imghover" width="294" height="47" alt="logo tony almazan">
                        </a>
                        <a class="text-center mx-sm-3 mx-2" href="tel:+13237709683">
                            <img :src="tonynumber" class="img-fluid imghover" width="189" height="30" alt="numero tony almazan">
                        </a>
                    </div>
            </div>
        </nav>
</template>
<script setup>

    import { ref } from "vue";
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    const icon = require("ASSETS/icons/search")  
    const tonygold = require("ASSETS/images/tonygold")
    const tonynumber = require("ASSETS/images/number") 
    const navicon = require("ASSETS/icons/nav")
    const navclose = require("ASSETS/icons/navclose")  

    const icon_tony = require("ASSETS/images/tonygold") 
    const icon_fb = require("ASSETS/icons/facebookGold")
    const icon_yt = require("ASSETS/icons/youtubeGold")
    const icon_ig = require("ASSETS/icons/instagramGold")

    // Hooks
    const router = useRouter()
    const store = useStore()

    const showModal = ref(false);
    const showIcon = ref(false);

    const navigateToHome = () => {
        router.push('/');
        window.scrollTo(0, 0); 
    }

</script>
<style lang="scss" scoped>

    .imghover:hover{
        transform: scale(1.2);
        cursor: pointer;

    } 
    .navhover:hover{
        transform: scale(1.2);
        border-bottom: 2px solid #D29751;
        cursor: pointer;

    } 

    .navicon{
        transform: scale(1.0);
        cursor: pointer;
    } 
    .navicon:hover{
        transform: scale(1.4);
        cursor: pointer;
    } 


    .buttonshine:hover {
        animation-name: shine;
        animation-duration: 350ms;
        transform: scale(1.1);
        box-shadow: 6px 6px 6px #000;

    }

    @keyframes shine {
    0% {
        background: linear-gradient(
        30deg, 
        hsl(260, 85%, 95%) 0%, 
        #D29751 25%
        );
    }

    25% {
        background: linear-gradient(
        30deg, 
        #D29751 0%, 
        hsl(260, 85%, 95%) 25%, 
        #D29751 50%);
    }

    50% {
        background: linear-gradient(
        30deg,
        #D29751 0%, 
        hsl(260, 85%, 95%) 50%, 
        #D29751 75%); 
    }

    75% {
        background: linear-gradient(
        30deg, 
        #D29751 0%, 
        hsl(260, 85%, 95%) 75%, 
        #D29751 100%); 
    }

    100% {
        background: linear-gradient(
        30deg, 
        #D29751 0%, 
        hsl(260, 85%, 95%) 100%); 
        }
    }  
    ////////////////////////////////////////////////
    .modalmn {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.222); /* Fondo oscuro semi-transparente */
    }
    .modal-content {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 210px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000bd; /* Fondo blanco */
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    .btn-no {
    width: 50px;  
    height: 50px;    
    background-color: #ffffff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    }
    .btn-si {
    width: 70%;      
    background-color: #7FDAE0;
    color: white;
    border: none;
    padding: 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    }
    .btn {
            margin: 20px 0px 10px 0px;
            border-radius: 10em;
            padding: 0.3em 1.5em;

            &:hover {
                color: #000000;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            }
        }
</style>