<template>
    <button class="btn btn-warning px-4 text-white buttonshine" @click="showModal = true">
    Registrarse y Pagar
    </button>                
    <div id="myModal" class="modalmn" v-show="showModal">
        <div class="modal-content">
                <div class="d-flex  align-items-center justify-content-center">
                    <button class="btn-no description-sm montserrat-bold position-absolute top-0 end-0 border border-warning" @click="showModal = false">X</button>
                </div>
                <div class="row justify-content-between">
                    <div class="flex-start-center h-100">
                        <div class="flex-start h-100">
                            <img :src="tonylogo" class="img-fluid imghover mx-2" style="max-height: 70px;">
                        </div>
                        <div class="flex-center-start h-100 ms-md-3 ms-1" >
                            <span class="text-hov description-lg text-white text-center mx-md-4 my-4 text-uppercase" style="font-weight: 800; text-shadow: 7px 7px 7px #000000; font-size: 25pt"> 
                                <b class="text-warning">
                                    REGÍSTRATE al
                                </b>
                                    curso
                                <br>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <label class="form-label text-white" for="typeText"></label>
                        <input v-model="name" type="text" placeholder="NOMBRE" class="form-control mb-1 description-xs" />
                    </div>
                    <div class="col-md-6 col-12">
                        <label class="form-label text-white" for="typeText"></label>
                        <input v-model="name" type="text" placeholder="APELLIDOS" class="form-control mb-1 description-xs" />
                    </div>
                    <div class="col-md-6 col-12">
                        <label class="form-label text-white" for="typeText"></label>
                        <input v-model="name" type="text" placeholder="TELÉFONO" class="form-control mb-1 description-xs" />
                    </div>
                    <div class="col-md-6 col-12">
                        <label class="form-label text-white" for="typeText"></label>
                        <input v-model="name" type="text" placeholder="CORREO" class="form-control mb-1 description-xs" />
                    </div>

                    <div class="col-md-6 col-12">
                        <label class="form-label text-white" for="typeEmail"></label>
                        <input v-model="email" type="email" placeholder="Password" class="form-control mb-1 description-xs" />
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-md-6 col-12 mt-3">
                        <button @click="sendMail()" 
                            class="btn btn-warning px-5 text-white w-100 buttonshine">
                                REGISTRAR
                        </button>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-md-6 col-12 mt-3">
                        <button @click="sendMail()" 
                            class="btn px-5 text-white w-100 buttonshine" style="background-color: #797878;">
                                ENTRAR A MI CURSO
                        </button>
                        <br>
                        <br>
                        <span class="montserrat-light text-white" style="font-size: 12px;">
                            Al dar “click en enviar” aceptas nuestros términos
                            y condiciones de privacidad.
                        </span>
                    </div>
                </div>

        </div>
    </div>
</template>
<script setup>
    import { ref } from "vue";
    const icon1 = require("ASSETS/icons/IconEmail")
    const icon2 = require("ASSETS/icons/IconPhone")
    const icon3 = require("ASSETS/icons/IconMaps")

    const icon_fb = require("ASSETS/icons/facebookGold")
    const icon_yt = require("ASSETS/icons/youtubeGold")
    const icon_ig = require("ASSETS/icons/instagramGold")

    const tonylogo = require("ASSETS/images/logo")


    const showModal = ref(false);
    
</script>
    
<style lang="scss" scoped>
    .modalmn {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(178, 178, 178, 0.5); /* Fondo oscuro semi-transparente */
    z-index: 100;
    }
    .modal-content {
    max-width: 600px;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000; /* Fondo blanco */
    padding: 20px;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    .btn-no {
    width: 30px;  
    height: 30px;    
    background-color: #030303;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    }
    .btn-si {
    width: 70%;      
    background-color: #7FDAE0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    }
</style>