<template>
    <ta-navbar></ta-navbar>

    <slot></slot>


    <ta-footer></ta-footer>
</template>

<script setup>
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"
    // Images

    // Vue imports

    // Images

    // Hooks
    const router = useRouter()
    const store = useStore()
    // Injects

    // Computeds

    // Variables


    // Methods

</script>


<style lang="scss" scoped>

</style>