import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Terms from '../views/Terms.vue'
import Course from '../views/Course.vue'
import Shopcart from '../views/Shopcart.vue'

// Modules

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            layout: 'default'
        },
        component: Home
    },
    {
        path: '/contact',
        name: 'contact',
        meta: {
            layout: 'default'
        },
        component: Contact
    },
    {
        path: '/terms',
        name: 'terms',
        meta: {
            layout: 'default'
        },
        component: Terms
    },
    {
        path: '/course',
        name: 'course',
        meta: {
            layout: 'default'
        },
        component: Course
    },
    {
        path: '/shopcart',
        name: 'shopcart',
        meta: {
            layout: 'default'
        },
        component: Shopcart
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
